import React, {useRef, useEffect} from "react";
import { FaPaperPlane } from "react-icons/fa";

import Layout from "../../components/layout";
// import ContactForm from "../../components/contact-form";
import heroImage from "../../images/contatti/0.jpg";
import { respH } from "../../lib/helpers";
import Seo from "../../components/seo";
import NewsLetter from "../../components/news-letter/news-letter.component";

const encMail = "aW5mb0BmdWNpbmF0YWxlbnRpLml0";

const ContactPage = () => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute("href", "mailto:".concat(atob(encMail)));
    }
  }, []);

  return (
    <Layout pageClass="contatti">
      <Seo title="Contatti" />
      <section
        className="hero is-large"
        style={{
          backgroundImage: `linear-gradient( 109.6deg, rgba(156,252,248,0.3), transparent, rgba(110,123,251,0.3)), url(${heroImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div className="hero-body">
          <h1 className={`title ${respH(1)} has-text-white has-text-centered`}>
            Contatti
          </h1>
        </div>
      </section>

      <section className="section is-medium pb-3">
        <div className="container is-max-desktop has-text-centered">
          <div className="columns">
            <div className="column">
              <p className={`title ${respH(1)} mb-6`}>
                Vuoi maggiori informazioni?
              </p>
              <a
                ref={ref}
                target="_blank"
                rel="noreferrer"
                href="mailto:email@address.com"
                className="button is-medium is-rounded"
              >
                <FaPaperPlane className="mr-3" /> Invia una mail
              </a>
            </div>
          </div>
        </div>
      </section>
      <NewsLetter />
    </Layout>
  );
};

export default ContactPage;
